import * as React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { ICON_MAP } from "../../shared/icons";

const fiatCardIcon = ICON_MAP.fiatCardIcon;
const fiatChevronIcon = ICON_MAP.fiatChevronIcon;
const fiatCryptoIcon = ICON_MAP.fiatCryptoIcon;

interface DepositSelectorProps {
  setShowForm: (show: boolean) => void;
}

const useStyles = makeStyles(() => ({
  container: {
    marginTop: "20px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  depositTitle: {
    fontSize: "16px",
    fontWeight: 700,
  },
  cardButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid #d1d5db",
    borderRadius: "12px",
    padding: "16px 20px",
    marginBottom: "16px",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.03)",
      cursor: "pointer",
    },
  },
  leftContent: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  textGroup: {},
  title: {
    fontSize: "16px",
    fontWeight: 600,
    color: "black",
  },
  subtitle: {
    fontSize: "14px",
    color: "#6b7280",
  },
  icon: {
    width: "30px",
    height: "30px",
  },
  chevron: {
    width: "24px",
    height: "24px",
    flexShrink: 0,
  },
}));

function getCryptoDepositPath() {
  let currentPath = window.location.pathname;
  return currentPath.replace("deposit_selection", "deposit?deposit_type=crypto&interstitial=pass");
}

const DepositSelector: React.FC<DepositSelectorProps> = ({ setShowForm }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div 
        className={classes.cardButton}
        onClick={() => setShowForm(true)}
      >
        <div className={classes.leftContent}>
          <img src={fiatCardIcon} alt="Card Icon" className={classes.icon} />
          <div className={classes.textGroup}>
            <div className={classes.title}>Deposit with Card</div>
            <div className={classes.subtitle}>
              Deposit USDC via debit or credit card
            </div>
          </div>
        </div>
        <img src={fiatChevronIcon} alt="Chevron" className={classes.chevron} />
      </div>

      <div 
        className={classes.cardButton}
        onClick={() => window.location.href = getCryptoDepositPath()}
      >
        <div className={classes.leftContent}>
          <img src={fiatCryptoIcon} alt="Crypto Icon" className={classes.icon} />
          <div className={classes.textGroup}>
            <div className={classes.title}>Deposit with Crypto</div>
            <div className={classes.subtitle}>
              Deposit USDC via the Ethereum Network
            </div>
          </div>
        </div>
        <img src={fiatChevronIcon} alt="Chevron" className={classes.chevron} />
      </div>
    </div>
  );
};

export default DepositSelector;
