import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export enum RequestType {
  GET = "get",
  POST = "post",
}

/**
 * Rails header is needed across all requests, so may as well
 * simplify the post request API.
 */
export const axiosRequest = async (
  url: string,
  data: any,
  type: RequestType
) => {
  const config = {
    headers: getRailsHeaders(),
  };
  let response = {};
  try {
    switch (type) {
      case RequestType.POST:
        response = await axios.post(url, data, config);
        break;
      case RequestType.GET:
        let getUrl = url;
        if (data) {
          getUrl += "?";
          Object.keys(data).forEach((key) => {
            getUrl += `${key}=${data[key]}&`;
          });
        }
        response = await axios.get(getUrl, config);
        break;
    }
  } catch (error) {
    /* https://stackoverflow.com/questions/49967779/axios-handling-errors */
    response = error.response;
  }
  return response;
};

/**
 * Rails header is needed across all requests, so may as well
 * simplify the post request API.
 */
export const typedAxiosRequest = async <T = any>(
  url: string,
  type: RequestType,
  data?: any,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  const requestConfig = {
    headers: getRailsHeaders(),
    ...config,
  };
  let response: AxiosResponse<T>;
  try {
    switch (type) {
      case RequestType.POST:
        response = await axios.post(url, data, requestConfig);
        break;
      case RequestType.GET:
        response = await axios.get(url, requestConfig);
        break;
    }
  } catch (error) {
    /* https://stackoverflow.com/questions/49967779/axios-handling-errors */
    response = error.response;
  }
  return response;
};

/**
 * Default headers required to get JS POST requests to work in Rails.
 * Make sure that <%= csrf_meta_tag %> is included in the view.
 * Full discussion: https://stackoverflow.com/questions/54808071/cant-verify-csrf-token-authenticity-rails-react
 */
export const getRailsHeaders = () => ({
  "Content-Type": "application/json",
  "X-CSRF-Token": document
    .querySelector("meta[name='csrf-token']")
    .getAttribute("content"),
});

export const successfulRequest = (response) => {
  return (
    Boolean(response) &&
    Boolean(response.data) &&
    (response.status === 200 || response.status === 201) &&
    typeof response.data === "object"
  );
};
