const investment = require("../../../assets/images/market/icons/shared/investment.svg");
const loot = require("../../../assets/images/market/icons/shared/loot.svg");
const moreOptionsSvg = require("../../../assets/images/market/icons/buttons/kebab_menu.svg");
const stakingSvg = require("../../../assets/images/market/icons/sidebar/bread-loaf.svg");
const lendLogo = require("../../../assets/images/index/for_investors/lending/products-lend-icon.png");
const badgeIconKarma = require("../../../assets/images/market/account/badge_icons/badge_icon_karma.svg");
const badgeIconPlus = require("../../../assets/images/market/account/badge_icons/badge_icon_plus.svg");
const badgeIconKYCVerified = require("../../../assets/images/market/account/badge_icons/badge_icon_kyc_verified.svg");
const badgeIconKYCIncomplete = require("../../../assets/images/market/account/badge_icons/badge_icon_kyc_incomplete.svg");
const badgeIconKYCNotStarted = require("../../../assets/images/market/account/badge_icons/badge_icon_kyc_not_started.svg");
const badgeIconKYCRejected = require("../../../assets/images/market/account/badge_icons/badge_icon_kyc_rejected.svg");
const badgeIconKYCProcessing = require("../../../assets/images/market/account/badge_icons/badge_icon_kyc_processing.svg");
const accountSettingsIcon = require("../../../assets/images/market/account/settings_icon.svg");
const accountSwitchEntityIcon = require("../../../assets/images/market/account/switch_entity_icon.svg");
const xMarkIcon = require("../../../assets/images/market/account/x_mark.svg");
const dataswapIcon = require("../../../assets/images/market/account/dataswap.svg");
const fiatCardIcon = require("../../../assets/images/fiat/card.svg");
const fiatChevronIcon = require("../../../assets/images/fiat/chevron.svg");
const fiatCryptoIcon = require("../../../assets/images/fiat/crypto.svg");

export const ICON_MAP = {
  investment,
  lendLogo,
  loot,
  moreOptionsSvg,
  stakingSvg,
  badgeIconKarma,
  badgeIconPlus,
  badgeIconKYCVerified,
  badgeIconKYCIncomplete,
  badgeIconKYCNotStarted,
  badgeIconKYCRejected,
  badgeIconKYCProcessing,
  accountSettingsIcon,
  accountSwitchEntityIcon,
  xMarkIcon,
  dataswapIcon,
  fiatCardIcon,
  fiatChevronIcon,
  fiatCryptoIcon
};
